<template>
  <div>
    <Topnav :homeShow="homeShow" @homeShow="homeShowA"></Topnav>
    <div class="" >
      <router-view />
    </div>
     <Footernav></Footernav>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      homeShow: false
    }
  },
  mounted() {
    if (this.$route.path == '/home') {
      this.homeShow = true
    } else {
      this.homeShow = false
    }
  },
  methods: {
    homeShowA(tt) {
      this.homeShow = tt
    }
  }
}
</script>
